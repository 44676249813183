import React, { useEffect, useState } from "react";
import "./section4.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section4 = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/aboutUs/sectionTwo/list`
        );
        setInfo(response.data);
      } catch (error) {
        console.error(error.message);
        setError("Loading...");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section3-container">Loading...</div>;
  }

  if (error) {
    return <div className="section3-container">{error}</div>;
  }

  if (!info || info.length === 0) {
    return <div className="section3-container">No data available.</div>;
  }

  const section = info[0];

  return (
    <section>
      <div className="about-section4-container">
        <div className="about-section4-details">
          <div className="about-section4-intro">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{section.title || "Default Title"}</span>
            </div>
            <div>
              <h3>{section.heading || "Default Heading"}</h3>
              <p>{section.description}</p>
            </div>
          </div>
          <div className="about-section4-info-container">
            {section.subSection.map((item, index) => (
              <div className="info" key={index}>
                <img src={`${SERVER_URL}/${item.icon}`} alt="icon" />
                <h5>{item.subHeading}</h5>
                <p>{item.subDescription}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section4;
