import React from "react";
import "./career.scss";
import Section1 from "../../components/career/Section1";

const Career = () => {
  return (
    <div className="career-page-container">
      <Section1 />
    </div>
  );
};

export default Career;
