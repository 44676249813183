import React, { useEffect, useState } from "react";
import "./section3.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section3 = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/aboutUs/sectionOne/list`);
        setInfo(response.data);
      } catch (error) {
        console.error(error.message);
        setError("Loading...");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section3-container">Loading...</div>;
  }

  if (error) {
    return <div className="section3-container">{error}</div>;
  }

  if (!info || info.length === 0) {
    return <div className="section3-container">No data available.</div>;
  }

  const section = info[0];

  return (
    <section>
      <div className="about-section3-container">
        <div className="about-section3-details">
          <div className="about-section3-intro">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{section.title || "Default Title"}</span>
            </div>
            <div>
              <h3>{section.heading || "Default Heading"}</h3>
              <p>{section.description}</p>
            </div>
          </div>
          {section.image && (
            <div className="about-section3-image">
              <img
                src={`${SERVER_URL}/${section.image}`}
                alt="Section visual"
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Section3