import React from "react";
import "./about.scss";
import Section1 from "../../components/home/Section2";
import Section2 from "../../components/home/Section3";
import Section3 from "../../components/about/Section3";
import Section4 from "../../components/about/Section4";
import Section5 from "../../components/about/Section5";

const About = () => {
  return (
    <div className="about-page-container">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  );
};

export default About;
