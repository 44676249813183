import React, { useEffect, useState } from "react";
import "./section5.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section5 = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/aboutUs/sectionThree/list`
        );
        setInfo(response.data);
      } catch (error) {
        console.error(error.message);
        setError("Loading...");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section3-container">Loading...</div>;
  }

  if (error) {
    return <div className="section3-container">{error}</div>;
  }

  if (!info || info.length === 0) {
    return <div className="section3-container">No data available.</div>;
  }

  const section = info[0];

  return (
    <div className="about-section5-container">
      <div className="about-section5-details">
        <div className="about-section5-intro">
          <div className="span-shape">
            <div className="shape"></div>
            <span>{section.title || "Default Title"}</span>
          </div>
          <div>
            <h3>{section.heading || "Default Heading"}</h3>
            <p>{section.description}</p>
          </div>
        </div>
        <div className="about-section5-info-container">
          {section.arrayInfo.map((item, index) => (
            <div className="info" key={index}>
              <img src={`${SERVER_URL}/${item.image}`} alt="icon" />
              <div className="text-content">
                <h5>{item.topic}</h5>
                <p>{item.details}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Section5